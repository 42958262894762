import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCategoryStore from "@/store/useCategoryStore"; // Importar el store de categorías
import { useNavigate } from "react-router-dom";
import useOnboardingStore from "@/store/useOnboardingStore"; // Store para el submit
import { NextStepGuardRouteString } from "../../guards/NextStepGuard";
import { CheckIcon } from "@heroicons/react/24/solid";

interface CategoryServices {
  categoryName: string;
  subCategoryId: string;
  subCategoryName: string;
  categoryId: string;
  services: { serviceId: string; serviceName: string }[];
  imageUrl: string;
}

interface ServiceOnboardingPageProps {
  onNext: () => void;
}
const ServiceCheckbox = ({
  isChecked,
  onClick,
  serviceName,
}: {
  isChecked: boolean;
  onClick: () => void;
  serviceName: string;
}) => (
  <div
    onClick={onClick}
    className="flex gap-2 items-center rounded-lg cursor-pointer transition-all px-4 py-2 border text-sm
      bg-white text-cTextSecondary dark:bg-dark-cBackground dark:text-dark-cTextSecondary border-cBorder border-opacity-20 select-none"
  >
    <div
      className={`min-w-[20px] min-h-[20px] w-[20px] h-[20px] flex items-center justify-center rounded
        transition-all duration-200 ease-in-out border
        ${
          isChecked
            ? "bg-bgBrand border-bgBrand"
            : "border-cBorder border-opacity-20"
        }`}
    >
      <CheckIcon className={`size-4  transition-all duration-200 ${isChecked ? "text-dark-cText stroke-dark-cText" : "text-cTextSecondary"}`} />
    </div>
    <span className="text-sm truncate overflow-hidden">{serviceName}</span>
  </div>
);

const ServiceOnboardingPage: React.FC<ServiceOnboardingPageProps> = ({
  onNext,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { availableServices, fetchAvailableServices, isLoading } =
    useCategoryStore();
  const { submitServices } = useOnboardingStore(); // Método para enviar los servicios seleccionados
  const [selectedServices, setSelectedServices] = useState<{
    [key: string]: string[];
  }>({});
  const [groupedSubcategories, setGroupedSubcategories] = useState<
    CategoryServices[]
  >([]);

  useEffect(() => {
    // Llamada para obtener los servicios disponibles
    fetchAvailableServices();
  }, [fetchAvailableServices]);

  // Efecto para agrupar los servicios por categorías una vez se cargan
  useEffect(() => {
    if (availableServices) {
      const grouped = availableServices.reduce(
        (acc: CategoryServices[], service) => {
          const subcategoryIndex = acc.findIndex(
            (c) => c.subCategoryId === service.subCategory.subCategoryId
          );
          if (subcategoryIndex > -1) {
            acc[subcategoryIndex].services.push({
              serviceId: service.serviceId,
              serviceName: service.serviceName,
            });
          } else {
            acc.push({
              categoryName: service.categoryName,
              categoryId: service.categoryId,
              subCategoryId: service.subCategory.subCategoryId,
              subCategoryName: service.subCategory.name,
              imageUrl: service.imageUrl,
              services: [
                {
                  serviceId: service.serviceId,
                  serviceName: service.serviceName,
                },
              ],
            });
          }
          return acc;
        },
        []
      );
      setGroupedSubcategories(grouped);
    }
  }, [availableServices]);

  console.log(groupedSubcategories);

  // Función para manejar la selección de servicios
  const toggleServiceSelection = (subCategoryId: string, serviceId: string) => {
    setSelectedServices((prev) => {
      const selectedInSubCategory = prev[subCategoryId] || [];
      if (selectedInSubCategory.includes(serviceId)) {
        return {
          ...prev,
          [subCategoryId]: selectedInSubCategory.filter(
            (id) => id !== serviceId
          ),
        };
      }
      return {
        ...prev,
        [subCategoryId]: [...selectedInSubCategory, serviceId],
      };
    });
  };

  // Función para manejar el botón continuar
  const handleContinue = () => {
    // Recolectar todos los servicios seleccionados
    const allSelectedServices = Object.values(selectedServices).flat();
    submitServices(allSelectedServices); // Llamada al método de envío
    try {
      const redirectTo = NextStepGuardRouteString();
      navigate(redirectTo);
      onNext(); // Call onNext after successful submission
    } catch (error) {
      console.error("Error submitting services:", error);
    } // Redirigir a la siguiente página
  };

  // Validación para asegurarse de que cada categoría tenga al menos un servicio seleccionado
  const canContinue = () => {
  return Object.values(selectedServices).flat().length > 0;
  };

  // Muestra un estado de carga mientras se obtienen los datos
  if (isLoading) {
    return <div className="text-center">Cargando...</div>;
  }

  // Verifica que `groupedSubcategories` no sea undefined
  if (!groupedSubcategories || groupedSubcategories.length === 0) {
    return (
      <div className="text-center">
        No se encontraron categorías disponibles.
      </div>
    );
  }

  const category = groupedSubcategories[0];

  return (
    <div className="min-h-screen p-8 bg-white font-sans">
      <div className=" flex flex-row items-center justify-between text-center mb-8">
        <h1 className="text-3xl font-bold">
          {t("onboarding.servicesSelection.title")}
        </h1>
        <div className="flex items-center gap-6 border border-cBorder border-opacity-20 rounded-lg p-4 max-w-fit">
          <div className="flex flex-col items-left justify-center text-left gap-4">
            <h3 className="text-sm font-semibold">Categoría seleccionada</h3>
            <h2 className="text-lg font-semibold">{category.categoryName}</h2>
          </div>
          <img
            src={category.imageUrl}
            alt="category photo"
            className="w-auto h-20 rounded-lg bg-cover"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {groupedSubcategories.map((subcategory) => (
          <div
            key={subcategory.subCategoryId}
            className="bg-white border border-cBorder border-opacity-20 rounded-lg p-6 shadow-sm"
          >
            <h3 className="text-lg font-semibold mb-4">
              {subcategory.subCategoryName}
            </h3>
            <div className="grid grid-cols-3 gap-3">
              {subcategory.services.map((service) => (
                <ServiceCheckbox
                  key={service.serviceId}
                  isChecked={selectedServices[
                    subcategory.subCategoryId
                  ]?.includes(service.serviceId)}
                  onClick={() =>
                    toggleServiceSelection(
                      subcategory.subCategoryId,
                      service.serviceId
                    )
                  }
                  serviceName={service.serviceName}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Botón Continuar */}
      <div className="flex justify-center mt-8">
        <button
          className={`${canContinue()
            ? "bg-bgBrand hover:bg-bgBrandHover text-textOnBrand px-8 py-3 rounded-lg"
            : "bg-cTextSecondary text-white px-8 py-3 rounded-lg disabled:opacity-50"}`}
          onClick={handleContinue}
          disabled={!canContinue()} // Deshabilitado hasta que todas las categorías tengan un servicio seleccionado
        >
          {t("Continuar")}
        </button>
      </div>
    </div>
  );
};

export default ServiceOnboardingPage;
