import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import useOnboardingStore from "../../store/useOnboardingStore"; // Importa tu store
import { useNavigate } from "react-router-dom";
import routesConfig from "../../config/routes.config"; // Asegúrate de que esté importado correctamente
import { NextStepGuardRouteString } from "../../guards/NextStepGuard";
import AddressAutocomplete from "../../components/molecules/AddressAutocomplete";

const countryCodes: { [key: string]: string } = {
  Colombia: "CO",
  "United States": "US",
  Canada: "CA",
  // ...
};

const StyledInput: React.FC<{
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}> = ({ label, value, onChange, placeholder }) => (
  <div className="flex flex-col gap-2">
    <label className="text-cTextSecondary dark:text-dark-cTextSecondary">
      {label}
    </label>
    <input
      type="text"
      value={value}
      onChange={onChange}
      className="w-full py-2 border-b text-cTextSecondary/50 dark:text-dark-cTextSecondary/50 placeholder:text-cTextSecondary/50 dark:placeholder-dark-cTextSecondary/50 focus:outline-none focus:ring-0"
      placeholder={placeholder}
    />
  </div>
);

const LocalizationOnboardingPage: React.FC<{ onNext: () => void }> = ({
  onNext,
}) => {
  const { t } = useTranslation();
  const { updateLocation, isLoading } = useOnboardingStore();
  const [country, setCountry] = useState("Colombia"); // Precargamos "Colombia"
  const [countryCode, setCountryCode] = useState(countryCodes["Colombia"]); // Default country code
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [locationError, setLocationError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificamos si los permisos de ubicación están habilitados
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getLocationData(latitude, longitude);
        },
        (error) => {
          setLocationError(t("onboarding.localization.locationError"));
        }
      );
    } else {
      setLocationError(t("onboarding.localization.geoNotSupported"));
    }
  }, [t]);

  const getLocationData = async (lat: number, lng: number) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyChrd6n58jSLu3G7s1EbQQxiZqmDTl5aDg`
      );
      if (response.data.results.length > 0) {
        const addressComponents = response.data.results[0].address_components;
        const formattedAddress = `${addressComponents[1].short_name} # ${addressComponents[0].short_name}`;
        console.log(response.data.results[0]);
        const extractedState = addressComponents.find((component: any) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;
        const extractedCity = addressComponents.find((component: any) =>
          component.types.includes("administrative_area_level_2")
        )?.long_name;

        setState(extractedState || "");
        setCity(extractedCity || "");
        setAddress(formattedAddress);
      }
    } catch (error) {
      console.error(
        "Error al obtener los datos de la API de Google Maps:",
        error
      );
      setLocationError(t("onboarding.localization.fetchLocationError"));
    }
  };

  const handleAddressSelect = (selectedAddress: string) => {
    setAddress(selectedAddress);
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);
    setCountryCode(countryCodes[selectedCountry] || "");
  };

  const handleContinue = async () => {
    if (country && state && city && address) {
      try {
        await updateLocation({
          departmentNameFromGM: state,
          countryNameFromGM: country,
          cityNameFromGM: city,
          address: address,
        });

        const redirectTo = NextStepGuardRouteString();
        navigate(redirectTo); // Ajusta la redirección según tu configuración de rutas
        onNext(); // Call onNext after successful submission
      } catch (error) {
        console.error("Error al actualizar la ubicación:", error);
      }
    } else {
      setLocationError(t("onboarding.localization.fillAllFields"));
    }
  };

  // Validar que todos los campos estén llenos para habilitar el botón "Continuar"
  const isButtonDisabled = !country || !state || !city || !address;

  return (
    <div className="w-full max-w-5xl mx-auto mt-36 bg-cBackground dark:bg-dark-cBackground">
      <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-8">
          {/* <h1 className="text-3xl font-bold mb-4">{t('onboarding.localization.welcomeTitle')}</h1> */}
          <img
            src="/assets/img/location.png"
            alt="Location"
            className="w-auto h-2/3 mx-auto"
          />
          <h1 className="text-xl font-medium text-cText dark:text-dark-cText">
            {t("onboarding.localization.title")}
          </h1>
          <p className="w-1/2 line-clamp-3 text-pretty text-center text-cTextSecondary dark:text-dark-cTextSecondary text-sm">
            {t("onboarding.localization.description")}
          </p>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <StyledInput
            label={t("onboarding.localization.country")}
            value={country}
            onChange={handleCountryChange}
            placeholder={t("onboarding.localization.enterCountry")}
          />
          <StyledInput
            label={t("onboarding.localization.department")}
            value={state}
            onChange={(e) => setState(e.target.value)}
            placeholder={t("onboarding.localization.enterDepartment")}
          />
          <StyledInput
            label={t("onboarding.localization.city")}
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder={t("onboarding.localization.enterCity")}
          />
          <StyledInput
            label={t("onboarding.localization.address")}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder={t("onboarding.localization.enterAddress")}
          />

          {locationError && <p className="text-red-500">{locationError}</p>}
          {/* <div className="mb-4">
        <label className="block text-gray-700">{t('onboarding.localization.address')}</label>
        <AddressAutocomplete
          country={countryCode}
          department={state}
          city={city}
          onSelect={handleAddressSelect}
        />
      </div> */}

          <div className="flex justify-center mt-8">
            <button
              onClick={handleContinue}
              disabled={isButtonDisabled || isLoading}
              className={`px-8 py-3 rounded-lg w-full cursor-pointer ${
                isButtonDisabled
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-bgBrand hover:bg-bgBrandHover text-white"
              }`}
            >
              {t("onboarding.localization.continueButton")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalizationOnboardingPage;
