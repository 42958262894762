import { create } from 'zustand';
import { submitServiceRequest, uploadImagesServiceRequest, fetchServiceRequestGetAll, fetchRequestByStatus } from '../services/request.service';
import { CreateServiceRequest, UploadImagesRequest } from '../types/request.types';
interface ServiceRequestStoreState {
  isLoading: boolean;
  error: string | null;
  response: string | null;
  resetStore: () => void
  addServiceRequestWithImages: (
    payload: CreateServiceRequest,
    imagesPayload: UploadImagesRequest
  ) => Promise<void>;
  getAllByUser: () => Promise<void>;
  getRequestsByStatus: (serviceRequestId: string, serviceRequestStatus: string) => Promise<void>;
}

const useServiceStore = create<ServiceRequestStoreState>((set) => ({
  isLoading: false,
  error: null,
  response: null,

  resetStore: () => {
    set({ isLoading: false, error: null, response: null });
  },

  getAllByUser: async () => {
    set({ isLoading: true, error: null, response: null });
    try {
      const response = await fetchServiceRequestGetAll();
      set({ isLoading: false, response });
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred';
      set({ isLoading: false, error: errorMessage });
    }
  },

  getRequestsByStatus: async (serviceRequestId: string, serviceRequestStatus: string) => {
    set({ isLoading: true, error: null, response: null });
    try {
      const response = await fetchRequestByStatus(serviceRequestId, serviceRequestStatus);
      set({ isLoading: false, response });
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred';
      set({ isLoading: false, error: errorMessage });
    }
  },

  addServiceRequestWithImages: async (payload, imagesPayload) => {
    set({ isLoading: true, error: null, response: null });
    try {
      const { serviceRequestId } = await submitServiceRequest(payload);
      
      if (imagesPayload.files.length > 0) {
        await uploadImagesServiceRequest(serviceRequestId, imagesPayload);
      }

      set({
        isLoading: false,
        response: `ServiceRequest creado y las imágenes subidas con ID: ${serviceRequestId}`,
      });
    } catch (error: any) {
      if(error.response) {

        set({ isLoading: false, error: error.response.status.toString() });
      }  else {
        set({ isLoading: false, error: 'An unknown error occurred' });
      }
     
    }
  },
}));

export default useServiceStore;