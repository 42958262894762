// src/guards/OnboardingCompletionGuard.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import routesConfig from '../config/routes.config';


export const OnboardingCompletionGuard: React.FC = () => {
  const nextStep = localStorage.getItem('nextStep');
  const pathname = window.location.pathname;
  const isOnboardingRoute = pathname.includes('onboarding-professional');
  const isProfessionalRoute = pathname.includes('professional');

  // Si está en ruta professional y el onboarding no está completo, redirige
  if (isProfessionalRoute && !isOnboardingRoute && nextStep && Number(nextStep) > 0 && Number(nextStep) < 6) {
    return <Navigate to={routesConfig.onboardingProfessionalPage}/>;
  }

  // Si el onboarding está completo y trata de acceder al onboarding, redirige al dashboard
  if (isOnboardingRoute && (!nextStep || Number(nextStep) === 0)) {
    return <Navigate to={routesConfig.dashboardProfessional} replace />;
  }

  return <Outlet />;
};