import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../organisms/layout/Header';
import Footer from '../organisms/layout/Footer';
import { useThemeStore } from '../../store/useThemeStore';

export const BaseLayout = () => {
  const { isDarkMode } = useThemeStore();
  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
      <div className="bg-white dark:bg-dark-cBackground text-cText dark:text-white">
        <Header />
        <main className="3xl:container 3xl:mx-auto">
          <Outlet />
        </main>
        <Footer/>
      </div>
    </div>
  );
};